.chat-conversation-wrapper {
  background-color: #f0f4f8;
  border-radius: 10px;
  padding: 20px;
}

.message-body {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.chat-message-wrapper {

}

.text-and-date-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.text-and-date-wrap.agent {
  align-items: flex-end;
}
.message-bubble-agent,
.message-bubble-visitor {
  border-radius: 15px;
  padding: 10px 15px;
  max-width: 70%;
  word-wrap: break-word;
}

.message-bubble-agent {
  background-color: #ededed;
  align-self: flex-start;
}
.message-bubble-agent .name-title {
  color: #000000;
}

.message-bubble-agent.user {
  border-bottom-left-radius: unset;
}

.message-bubble-agent.user .name-title {
  color: #000000;
  line-height: 15px;
}

.chat-text {
  font-size: 14px;
  line-height: 20px;
  margin-top: 2px;
}

.message-bubble-agent.user .chat-text {
  color: #000000;
}
.message-bubble-visitor .chat-text {
  color: white;
}

.message-bubble-visitor {
  background-color: var(--brand-color);
  color: #ffffff;
  align-self: flex-end;
  border-bottom-right-radius: unset;
}

.name-title {
  font-size: 14px;
  color: white;
  font-weight: 500;
}
.date-badge {
  font-size: 0.8em;
  color: #888;
}

/* Additional styles for the header */
.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
}

.chat-title {
  font-size: 1.2em;
  font-weight: bold;
}

.chat-status {
  color: #4CAF50;
}