/* This is for only desktop version */
@media only screen and (min-width: 768px) {
  .desktop-hide {
    display: none;
  }
  .mobile-sidebar-cross {
    display: none;
  }
  .mobile-version-kpi-wrapper {
    display: none;
  }
  .landing-header-toggle {
    display: none;
  }
  .burger-menu-icon {
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* CSS rules for large devices */
  .chat-conversation-wrapper {
    height: 50vh;
  }
}

/* Media query for extra-large devices */
@media screen and (min-width: 1200px) {
  /* Your CSS rules for extra-large devices go here */
  .landing-header-wrapper {
    max-width: 70%;
  }
  /* Add more styles as needed */
  .chat-conversation-wrapper {
    height: 72vh;
  }
  
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* CSS rules for extra large devices */
  .chat-conversation-wrapper {
    height: 61vh;
  }
  .recent-chat {
    max-height: 62vh;
  }
  .group-member-wrapper {
    max-height: 190px;
  }
  .group-member-wrapper.groups {
    max-height: 60vh;
  }
  .chat-message-body {
    /* background: green; */
  }
  .recent-chat {
    max-height: 61vh;
}
.omni-profile-overview {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  height: 83vh;
  overflow-y: auto;
}
}

/* XXL devices (very large desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* CSS rules for XXL devices */
  .chat-conversation-wrapper {
    height: 50vh;
  }
  .chat-message-body {
    /* background: red; */
  }
  .chat-conversation-wrapper {
    height: 74vh;
  }
  .recent-chat {
    max-height: 74vh;
}
.omni-profile-overview {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  max-height: 89vh;
  overflow-y: auto;
}
.group-react-chat {
  height: unset;
}
}

/* This is for mobile or small devices version */
@media only screen and (max-width: 768px) {
  .mobile-version-kpi-wrapper {
    display: none;
  }
  .mobile-hide {
    display: none;
  }
  .container {
    padding: 0px;
    max-width: 92%;
  }
  header {
    padding: 0px 10px !important;
  }

  aside.sidebar {
    /* width: 0px; */
  }
  aside.sidebar.active {
    width: 80% !important;
  }
  .featured-status-wrapper,
  .chart-wrapper {
    flex-direction: column;
  }
  .chart-wrapper {
    margin-top: 15px;
    grid-gap: 10px;
    display: none;
  }
  .date-filter {
    width: 100%;
  }
  .p-20 {
    padding: 0px;
  }
  .analytics-wrapper {
    grid-template-columns: 1fr;
  }
  .table-container {
    margin: 0px;
  }
  .table-filter-container {
    flex-direction: column;
    display: none;
  }
  .table-filter {
    flex-direction: column;
  }
  .create-user-form {
    max-width: 95%;
  }
  .pagination-wrapper {
    margin-top: unset;
  }
  .integrate-container {
    padding: 0;
  }
  .integrate-wrapper {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }
  .settings-container {
  }
  .settings-wrapper form {
    /* padding: 15px; */
    display: flex;
    flex-direction: column-reverse;
  }
  main {
    width: 100%;
    margin: 0px;
  }
  main.active {
    width: 100%;
    margin-left: 0px;
  }
  .body-wrapper {
    padding: 10px;
    width: 100%;
  }
  .search-box input {
    height: 38px;
    width: 230px;
  }
  .breadcrumbs-and-filter {
    justify-content: flex-end;
  }
  .featured-status-wrapper {
    margin-top: 10px;
    grid-gap: 10px;
    overflow-x: scroll;
  }
  .search-box {
    display: none;
  }
  .chart-svg-wrapper {
    height: 170px;
    overflow: hidden;
  }
  aside.sidebar.mobile-active {
    position: fixed;
    width: 100%;
    z-index: 999;
  }
  .not-found-content h1 {
    font-size: 120px;
    line-height: 160px;
  }
  .not-found-wrapper {
    max-width: 95%;
  }
  /* admin */
  .chat-body-sidebar {
    /* display: none; */
    min-width: unset;
    min-height: unset;
    max-height: unset;
    height: 100%;
    max-width: 100%;
    padding: 10px;
  }
  .admin-header {
    /* position: fixed; */
    width: 100%;
    flex-direction: row;
    min-width: unset;
    max-width: unset;
    height: unset;
    min-height: unset;
    z-index: 1;
    flex-direction: column;
    padding: unset;
  }
  .admin-page-wrapper {
    height: unset;
    flex-direction: column;
  }
  .admin-header ul:last-child {
    display: none;
  }
  .admin-header ul {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    grid-gap: 0px;
    flex-direction: revert;
    width: 100%;
    position: fixed;
    align-items: center;
    bottom: 0px;
    background: white;
    padding: 10px;
    box-shadow: 0px 0px 5px 0px lightgray;
    height: 60px;
  }

  header.active {
    width: 100%;
    background: white;
    box-shadow: 0px 0px 3px 0px lightgray;
    z-index: 999;
  }

  .dashboard-kpi-wrapper {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
  .user-details-description-content {
    margin-top: 10px;
    flex-wrap: wrap;
    grid-gap: 10px;
  }
  .user-details-header {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    grid-gap: 15px;
    padding-bottom: 20px;
    margin-bottom: unset;
  }
  .user-details-description {
    overflow-x: auto;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .user-details-card {
    padding: 25px;
    margin: 15px 0px;
  }
  .user-details-header h3 {
    font-size: 20px;
    line-height: 30px;
  }
  .register-wrapper form {
    padding: 20px;
    border-radius: 8px;
    width: 100%;
  }
  .form-button {
    padding: 10px 15px;
    /* width: 100%; */
  }
  .dashboard-section {
    padding: 10px;
  }
  .dashboard-title {
    flex-direction: column;
    grid-gap: 10px;
  }
  .dashboard-container {
    padding: 10px !important;
  }
  .search-container {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr;
    margin-bottom: 15px;
  }
  .form-group select {
    width: 100%;
  }
  .user-container {
    margin: 10px;
    height: auto;
    border-radius: 3px;
    padding: 10px;
  }
  .pagination-wrap {
    grid-gap: 20px;
    margin-top: 15px;
    flex-direction: column;
    grid-gap: 0px;
    padding-bottom: 15px;
  }
  .dashboard-kpi-item {
    padding: 25px;
  }
  .search-user {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .pagination-status {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  h3 {
    padding: unset;
  }
  .full-width {
    width: 100%;
  }
  .dashboard-kpi-header {
    margin-bottom: unset;
  }
  .ant-table-content {
    width: 100%;
    overflow-x: auto;
  }
  .all-ticket-list-screen h3 {
    margin: 15px 0px;
  }
  .dashboard-kpi-item {
    padding: 10px;
    box-shadow: var(--shadow);
  }
  .dashboard-kpi-count {
    font-size: 20px !important;
    width: 40px;
  }
  .ticket-details p {
    font-size: 14px;
    margin-bottom: unset;
    line-height: 25px;
    font-family: poppins;
  }
  .ticket-sidebar-box h3 {
    font-size: 16px;
    padding: 10px 0px;
  }
  .ticket-drawar-title h2 {
    font-size: 18px;
  }
  .appointment-log-btn {
    background: #d0d0d0;
    padding: 3px 10px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 13px;
  }
  .appointment-log {
    margin-bottom: unset;
  }
  .ticket-drawar-title {
    margin-bottom: 15px;
  }
}
