/*
@File: Wipdata ChatBot Dashboard

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the template.

/* import fonts */
@font-face {
  font-family: 'flexo';
  src: url('../fonts/Flexo-Thin.woff') format('woff'),
       url('../fonts/Flexo-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'flexo';
  src: url('../fonts/Flexo-Light.woff') format('woff'),
       url('../fonts/Flexo-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'flexo';
  src: url('../fonts/Flexo-Regular.woff') format('woff'),
       url('../fonts/Flexo-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'flexo';
  src: url('../fonts/Flexo-Medium.woff') format('woff'),
       url('../fonts/Flexo-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'flexo';
  src: url('../fonts/Flexo-Bold.woff') format('woff'),
       url('../fonts/Flexo-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'flexo';
  src: url('../fonts/Flexo-Heavy.woff') format('woff'),
       url('../fonts/Flexo-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

/*
Default Style
============================*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* inter font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');
/* font-family: 'Inter', sans-serif; */

/* Montserrat font */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap');

:root {
  /**
  @font family declaration
  */
  --font-poppins: 'Poppins', sans-serif;
  --font-montserrat: 'Montserrat', sans-serif;
  --font-flexo: 'flexo', sans-serif;
  /**
  @color declaration
  */
  --brand-color: #967f65;
  --brand-color-bold: #1d4ed8;
  --secondary-color: #35a989;
  --paragraph-color: #4d4d4d;
  --paragraph-size: 17px;
  --orange-color: #fd346e;
  --heading-color: #292930;
  --gray-color: gray;
  --gray-color-2: #f8f8f8;
  --bg-color: #f2f4f8;
  --border-color: #eeeeee;
  --font-bold: 500;
  --white-color: white;
  --wip-color: #f92c01;
  --wip-bg-img-color: radial-gradient(
    circle at top right,
    #fda40c 0%,
    #f92c01 100%
  );
  --secondary-bg: linear-gradient(115deg, rgb(42, 39, 218), rgb(0, 204, 255));
  --purpleColor: #c211a1;
  --shadow: 0 1px 1px rgb(0 0 0 / 10%);
  --hover-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  --header-height: 65px;
  --radius: 4px;
  --font-color: #35a989;
  --green-color: #009688;
  --danger-color: hsl(11deg 100% 37.8% / 86%);
  --transition: all 0.3s ease-in-out;
  --sidebar-width: 5%;
  --sidebar-width-active: 18%;
  --sidebar-body-width: 95%;
  --sidebar-body-width-active: 82%;
  --green-color: #0566ff;
  --green-bg: #0566ff;
  --sidebar-icon-width: 68px;
  --blue-color: #35a989;
}

.dark-theme {
  --bg: #151521;
  --bg-light: #1e1e2d;
  --p-color: #565674;
  --p-light-color: #d7d7d7;
  --h-color: #ffffff;
  --gray: gray;
  --model-bg: #000000cc;
  --popup-bg: #ffffffba;
  --loading-dot-bg: #3d3d53;
  --shadowColor: #1e1e2d;
}
.light-theme {
  --bg: #f8f7fa;
  --bg-light: #ffffff;
  --p-color: #565674;
  --p-light-color: #607d8b;
  --h-color: #565674;
  --model-bg: #ffffffba;
  --popup-bg: #000000d1;
  --loading-dot-bg: #3d3d53;
  --shadowColor: lightgray;
}

/* html {
  scroll-behavior: smooth;
} */
/* html {
  overscroll-behavior: contain;
} */
.overscroll-off {
  overscroll-behavior: contain;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* scroll-behavior: smooth; */
}

/*---------------------------------
	typography css start 
---------------------------------*/
body {
  /* font-family: var(--font-flexo) !important; */
  font-family: 'flexo' !important;
  font-size: 14px;
  font-weight: 400;
  color: var(--p-color);
  line-height: 26px;
  overflow-x: hidden;
  transition: var(--transition);
  overscroll-behavior: none;
}

a {
  text-decoration: none;
  transition: 0.3s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  margin-top: 0px;
  font-weight: var(--font-bold);
  line-height: 1.2;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

ul {
  margin: 0px;
  padding: 0px;
}

p {
  font-size: 18px;
  font-weight: 400;
  color: var(--paragraph-color);
  margin: unset;
}
input,
select,
textarea {
  background: var(--bg);
  border: 1px solid var(--bg-light);
  border-radius: 4px;
  border-radius: var(--radius);
  color: #292930;
  color: var(--heading-color);
  font-size: 17px;
  outline: none;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  transition: var(--transition);
  width: 100%;
}
.z-index {
  position: relative;
  z-index: 3;
}
.common-button {
  padding: 8px 12px;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  color: white;
  background: #009688;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  position: relative;
}
.common-button span {
  display: flex;
  align-items: center;
  grid-gap: 3px;
}
.common-button svg {
  color: white;
}
.common-margin {
  margin: 30px 0px;
}
.p-relative {
  position: relative !important;
}
.loading-btn {
  width: 110px;
  height: 35px;
  background: #c5c5c5;
  cursor: not-allowed !important;
}
a,
.btn,
button,
input,
select,
textarea,
li,
img,
svg,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  /* font-family: var(--font-montserrat); */
}
input,
textarea,
select {
  padding: 10px;
  border: 1px solid #80808038;
  border-radius: var(--radius);
  transition: var(--transition);
  outline: none;
  font-size: 17px;
  width: 100%;
  color: var(--heading-color);
  background: var(--bg);
}
input:focus,
textarea:focus,
select:focus {
  border: 1px solid var(--brand-color);
}
textarea {
  height: 150px;
  line-height: 26px;
  resize: vertical;
}
li {
  list-style: none;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}
.btn {
  font-size: 15px;
  font-weight: 500;
  background: var(--brand-color);
  color: white;
  padding: 10px 15px;
  border-radius: 3px;
  width: max-content;
}
.btn-mini {
  padding: 7px 12px;
  font-size: 13px;
}
a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

svg {
  font-size: 22px;
  color: var(--gray-color);
  cursor: pointer;
}

a,
button {
  color: inherit;
  outline: none;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: 0;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

input {
  outline: none;
  font-family: var(--var(--font-flexo));
}

input[type='color'] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0;
  border-radius: 50%;
}

*::-moz-selection {
  background: var(--green-color);
  color: var(--white-color);
  text-shadow: none;
}

::-moz-selection {
  background: var(--green-color);
  color: var(--white-color);
  text-shadow: none;
}

::selection {
  background: var(--green-color);
  color: var(--white-color);
  text-shadow: none;
}

*::-moz-placeholder {
  color: rgb(189, 189, 189);
  font-size: 15px;
  opacity: 1;
  font-weight: 200;
}

*::placeholder {
  color: rgb(173, 173, 173);
  font-size: 15px;
  opacity: 1;
  font-weight: 400;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-weight: 700;
}
.text-danger {
  color: var(--danger-color) !important;
  font-weight: 400;
  font-size: 15px;
}
.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}
/* Dashboard / Home screen Style
================================ */
section.main-section {
  width: 100%;
}
.dashboard-wrapper {
  width: 100%;
  background: var(--bg-light);
}
aside.sidebar {
  width: 100%;
  background: var(--bg-light);
  height: 100%;
}

aside.sidebar ul {
  display: flex;
  flex-direction: column;
}

aside.sidebar ul li {
}
.dropdown-icon {
  margin-right: 0px;
}

aside.sidebar ul li a {
  font-size: 16px;
  color: black;
  font-weight: 400;
  padding: 10px 0px;
  position: relative;
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
  align-items: center;
}
.grouped-item {
  border-bottom: 1.8px solid #d3d3d32e;
  padding-top: 15px;
}
.grouped-item:last-child {
  border-bottom: unset;
}
.sub-heading {
  text-transform: uppercase;
  font-weight: 500;
  color: black;
  font-size: 14px;
}
.flex-label-icon {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  align-items: center;
  font-weight: 500;
  width: 100%;
}
.flex-label-icon p {
  margin: 0;
}

.flex-label-icon svg {
  width: 20px;
}
.active-link svg,
.active-link span {
  color: var(--brand-color);
}
/* .active-link::before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 5px;
  background: #03a9f4;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
} */
aside.sidebar ul li svg {
  font-size: 25px;
}
main {
  width: 100%;
  box-sizing: border-box;
  transition: var(--transition);
}
/* Header */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: var(--transition);
  /* position: sticky;
  top: 0px; */
  z-index: 999;
  height: var(--header-height);
  background: var(--white-color);
  padding: 0px;
}
header.plain-header {
  padding: 0px 50px;
}
header.active {
  position: fixed;
  top: 0;
  right: 0;
  width: 80%;
  z-index: 999;
  transition: var(--transition);
}

.header-logo img {
  width: 100%;
  object-fit: contain;
}
.header-option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 50px;
}
.search-box {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.search-box input {
  height: 40px;
  border: 1px solid var(--bg);
  background: var(--bg);
  width: 400px;
  padding-left: 40px;
  border-radius: 5px;
}
.search-box svg {
  position: absolute;
  left: 10px;
}
.burger-menu-icon {
  font-size: 30px;
  cursor: pointer;
}
.header-profile {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 20px;
  position: relative;
}
.header-profile p {
  font-size: 15px;
  line-height: 22px;
  color: var(--p-light-color);
}
.header-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.header-profile svg {
  color: var(--gray-color);
}
.body-container {
  width: 100%;
  min-height: 90vh;
}
.body-wrapper {
  /* padding: 30px; */
  width: 100%;
  transition: var(--transition);
}

.container {
  max-width: 1250px;
  margin: auto;
  /* padding: 0px 20px 0px 20px; */
}

/* breadcrumbs-and-filter */
.breadcrumbs-and-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 20px; */
  display: none;
}
.blank {
  display: none;
}
.breadcrumbs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 3px;
  color: var(--gray-color);
  font-size: 16px;
}
.date-filter {
  background: var(--bg-light);
  padding: 7px 20px;
  border-radius: 30px;
  box-shadow: var(--shadow);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
  color: var(--gray-color);
  cursor: pointer;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.date-filter h6 {
  color: lightslategrey;
  font-weight: 500;
}
.date-filter svg {
  font-size: 20px;
}

.header-logo-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 6px;
  text-transform: capitalize;
}
.header-logo {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: var(--hover-shadow);
}
.header-sidebar-logo {
  height: 35px;
  justify-content: flex-start;
  width: 100%;
}
.header-sidebar-logo img {
  height: 100%;
  object-fit: contain;
  width: 100%;
}
.header-logo-content {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}
.header-logo-content svg {
  font-size: 17px;
}
.header-logo-content p,
.header-logo-content svg {
  color: var(--brand-color);
}
.dropdown-wrapper {
  grid-gap: 8px;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  right: 0;
  top: 50px;
  width: -moz-fit-content;
}
.dropdown-wrapper span {
  color: gray;
}
/* Footer dashboard */
.dashboard-footer-wrapper {
  margin-top: 30px;
  gap: 0px;
}
.footer-wrapper {
  grid-gap: 25px;
  align-items: center;
  background: #fff;
  border-top: 2px solid #d3d3d324;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
}
.footer-logo {
  width: 80px;
  height: 100%;
}
.footer-logo img {
  width: 100%;
  height: 100%;
  margin-left: -10px;
}
.footer-menu {
}
.footer-menu .landing-page-menu li a {
  font-weight: 600;
  font-size: 16px;
}
.footer-bottom-info p {
  font-size: 16px;
}

/* Login and Register */
.form-title {
  margin-bottom: 10px;
}
.form-title h2 {
  font-size: 20px;
  text-align: center;
}
.register-section {
  background: var(--bg);
  height: 100vh;
  background: var(--brand-color);
}
.register-container {
  width: 100%;
  z-index: 99;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.password-model {
  visibility: hidden;
  opacity: 0;
  transition: var(--transition);
}
.password-model.active {
  visibility: visible;
  opacity: 1;
  z-index: 9999;
}
.register-wrapper {
}
.register-wrapper form {
  height: 100%;
  margin: auto;
  background: var(--bg-light);
  padding: 30px;
  border-radius: 8px;
  width: 550px;
  background: white;
  box-shadow: var(--hover-shadow);
}
.form-row {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
}
.form-group {
  width: 100%;
  margin-bottom: 10px;
}
.button-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
.form-group:last-child {
  margin-bottom: 0px;
}
.form-group label {
  color: var(--gray-color);
  font-size: 12px;
  font-weight: 300;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
}
.form-group-input-icon {
  position: relative;
}
.form-group-input-icon input {
  position: relative;
}
.form-group-input-icon svg {
  position: absolute;
  right: 15px;
  top: 10px;
}
/* .form-group input, .form-group select{
  height: 50px;
} */
.error-text {
  color: #c12300db !important;
  color: var(--danger-color) !important;
  font-size: 13px;
  line-height: 20px;
}
.error-input {
  border: 1px solid #c12300db !important;
  border: 1px solid var(--danger-color) !important;
}
.form-group select {
  width: max-content;
}
.form-button {
  width: max-content;
  background: var(--brand-color);
  padding: 11px 15px;
  border-radius: 3px;
  box-shadow: var(--shadow);
  color: white;
  font-size: 16px;
  transition: var(--transition);
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  font-weight: 500;
  border: 1px solid var(--brand-color);
  /* position: relative; */
  /* margin-top: 20px; */
}

/* Dashboard
============== */
.dashboard-section {
  padding: 30px;
}
.dashboard-container {
  background: var(--white-color);
  padding: 30px;
}
.dashboard-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 5px;
}
.dashboard-title h2 {
  font-size: 25px;
}
.dashboard-container {
  background: var(--white-color);
  border-radius: 3px;
}
.dashboard-kpi-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}
.dashboard-kpi-item {
  background: #d9d9d959;
  border-radius: 3px;
  padding: 20px;
}
.dashboard-kpi-title {
  display: flex;
  grid-gap: 10px;
  justify-content: center;
  align-items: center;
}
.dashboard-kpi-header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  margin-bottom: 20px;
  font-weight: 500;
}
.dashboard-kpi-header p {
  color: var(--brand-color);
  font-weight: 600;
  font-size: 16px;
  color: black;
}
.dashboard-kpi-count {
  font-weight: 800 !important;
  font-size: 25px !important;
  color: var(--brand-color) !important;
  background: var(--white-color);
  padding: 5px;
  border-radius: 5px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-dropdown {
}
.dashboard-kpi-item-body {
  background: white;
  padding: 10px;
  border-radius: 3px;
}

.chart-svg-wrapper {
  height: 300px;
  width: 100%;
}
.recharts-layer.recharts-cartesian-axis-tick {
  font-size: 12px;
}
.header-section {
  background: var(--white-color);
  box-shadow: var(--shadow);
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagination-wrap {
  display: flex;
  justify-content: flex-start;
  grid-gap: 20px;
  margin-top: 30px;
}
.border-unset {
  border: unset !important;
}
/* user details  */
.user-details-container {
}
.user-details-card {
  background: var(--white-color);
  padding: 30px;
  border-radius: 3px;
  margin: 30px 0px;
}
.user-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #d3d3d359;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.user-details-description h4 {
  font-size: 15px;
  font-weight: 600;
}
.user-details-description-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 30px;
  margin-top: 10px;
}
.user-des-content-wrap {
}
.user-des-content-wrap span:first-child {
  font-weight: 600;
}

/* dashboard antd select */
.custom-antd-select-box .ant-select-selector {
  border: none !important;
  background-color: transparent !important;
}
.custom-antd-select-box .ant-select-selector .ant-select-selection-item {
  padding-inline-end: 25px;
}
.list-of-blog-wrapper {
  margin-top: 20px;
}
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px;
  background: white;
  padding: 10px 10px;
  border-radius: 3px;
  margin-bottom: 20px;
}
.default-btn {
  background: var(--brand-color);
  padding: 8px 20px;
  border-radius: 10px;
  color: white;
  font-size: 14px;
  font-family: var(--font-flexo);
}
.table-title h2 {
  font-size: 19px;
}
.sidebar-btn {
  margin-top: 15px;
}

/* admin chat body */
.chat-body-wrapper {
  display: grid;
  grid-template-columns: 11fr 4fr;
  width: 100%;
  height: 100%;
  transition: var(--transition);
  position: relative;
  grid-gap: 15px;
}
.chat-body-wrapper.active {
  position: fixed;
  width: 100%;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 9999999999999;
  background: var(--bg-light);
}
.chat-body-sidebar {
  padding: 20px;
  /* border: 1px solid lightgray;
  border-top: unset;
  border-bottom: unset; */
  /* min-width: 400px;
  max-width: 400px; */
  background: var(--bg);
  height: 100%;
  margin-top: 1px;
  padding-bottom: unset;
  min-width: 290px;
}
.chatbox-sidebar-wrap {
  overflow: hidden;
}
.chat-body-search {
  position: relative;
  width: 100%;
}
.omni-sidebar-and-chatlogs {
  display: grid;
  grid-template-columns: 2fr 6fr;
  background: white;
  border-radius: 10px;
  overflow: hidden;
}
.chat-body-search input {
  padding: 5px 10px;
  font-size: 15px;
  border-radius: 8px;
  border: unset;
  width: 100%;
  outline: none;
  background: var(--bg-light);
  font-family: var(--font-flexo);
  padding-left: 10px;
  border: 1px solid #d3d3d352;
}
.chat-body-search svg {
}
.featured-chats {
  display: flex;
  justify-content: flex-start;
  grid-gap: 5px;
}
.featured-chat-item {
  background: var(--bg-light);
  border-radius: 5px;
  padding: 4px 13px;
}
.featured-chat-item p {
  color: var(--gray-color);
  font-size: 12px;
  text-align: center;
  margin-top: 3px;
}
.featured-chat-thumb {
  width: 50px;
  height: 50px;
  position: relative;
}
.featured-chat-thumb img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: contain;
}
.chatbox {
  width: 100%;
  /* display: flex;
  justify-content: space-between;
  flex-direction: column; */
}
.chat-message-body {
  width: 100%;
}
.chatbox-wrapper {
  width: 100%;
  position: relative;
  border: 1px solid var(--border-color);
  border-top: unset;
  border-bottom: unset;
}
.audio-hidden {
  display: none;
}

/* recent chat */
.chat-title {
  margin: 8px 0px;
  color: var(--gray-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}
.add-new-group-icon {
  background: var(--bg-light);
  border-radius: 50%;
  font-size: 30px;
  padding: 5px;
  box-shadow: var(--hover-shadow);
}
.create-group-wrapper {
  margin-top: 10px;
}
.create-group-btn {
  background: var(--brand-color);
  padding: 10px 20px;
  border-radius: var(--radius);
  color: white;
  font-size: 16px;
  width: fit-content;
  margin-top: 7px;
}
.chat-title:first-child {
  margin-top: unset;
  margin-bottom: 0px;
}
.chat-title:last-child {
  margin-bottom: 10px !important;
}
.chat-title h2 {
  font-weight: 500;
  font-size: 15px;
  font-family: montserrat;
  color: var(--gray);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}
.chat-title-big h2 {
  font-size: var(--title-size);
  font-weight: 700;
  color: var(--gray);
}

.recent-chat {
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  transition: all 0.3s ease 0s;
  max-height: 65vh;
  scrollbar-width: thin;
  position: relative;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 7px;
  padding-right: 3px;
}
.pinned-recent-chat {
  height: 180px;
}
/* recent chat scrollbar width */
.recent-chat::-webkit-scrollbar {
  width: 7px;
  overflow-y: hidden;
}

/* recent chat scrollbar Track */
.recent-chat::-webkit-scrollbar-track {
  background: #f1f1f193;
  overflow-y: hidden;
}

/* recent chat scrollbar Handle */
.recent-chat::-webkit-scrollbar-thumb {
  background: rgba(204, 204, 204, 0.486);
  border-radius: 10px;
  overflow-y: hidden;
}

/* recent chat scrollbar Handle on hover */
.recent-chat::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}

.recent-chat-wrap {
  display: flex;
  flex-direction: column;
  grid-gap: 7px;
}
.recent-chat-item {
  background: var(--bg-light);
  padding: 10px;
  border-radius: 3px;
  transition: background 0.3s ease-in;
  cursor: pointer;
  border-left: 2px solid transparent;
  border: 1px solid var(--border-color);
  width: 100%;
}
.recent-chat-item.active {
  border: 2px solid var(--brand-color);
}
.recent-chat-item.active .recent-chatbox-content h2 {
  color: var(--blue-color) !important;
}
.recent-chat-item.active .recent-chatbox-user-chat svg,
.recent-chat-item.active .recent-chatbox-user-chat span,
.recent-chat-item.active .recent-chat-item-inner p,
.recent-chat-item.active .channel-right svg {
  color: var(--p-light-color) !important;
}
.recent-chat-item:hover {
  background: #cedeff;
}
.recent-chat-item.pending {
  border-left: 3px solid #ffd600;
}
.recent-chat-item.opened {
  border-left: 3px solid #06bf20;
}
.recent-chat-item.closed {
  border-left: 3px solid #f60808;
}

.recent-chat-item:hover .channel-right {
  background: #cedeff;
  visibility: visible;
  opacity: 1;
}
.recent-chat-avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  box-shadow: var(--shadow);
  position: relative;
}
.recent-chat-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.recent-chat-item-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: black;
}
.recent-chat-item-inner.skeleton {
  display: unset;
}
.recent-chat-item-inner p {
  color: #7a7f9a;
  font-size: 12px;
}
.recent-chat-item-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 10px;
}
.recent-chat-item-content.skeleton {
  display: unset;
}
.recent-chatbox-content {
}
.recent-chatbox-content h2 {
  font-size: 15px;
  font-weight: 500;
  color: var(--gray-color);
  margin-bottom: unset;
}
.recent-chatbox-content p {
  font-size: 13px;
  color: #7a7f9a;
}
.recent-chatbox-user-chat {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 5px;
}
.recent-chatbox-user-chat svg {
  color: #7a7f9a;
  font-size: 12px;
}
.recent-chatbox-user-chat span {
  color: #7a7f9a;
  font-size: 12px;
}
.typing-item-wrap {
  margin-top: 5px;
}
.typing-wrap {
  padding: 9px 13px !important;
}
.typing-wrap .typing {
  color: #000 !important;
}
.typing-in-chat {
  color: #7269ef !important;
  font-weight: 500;
}
.typing-in-chat .animate-typing .dot {
  background: #7269ef !important;
  margin-right: 3px;
}

.analytics-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  width: 100%;
  background-color: var(--bg-light);
  padding: 10px;
  border-radius: 3px;
  margin-top: 10px;
  text-align: center;
  box-shadow: var(--shadow);
  height: 100%;
}
.analytics-guide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 10px;
  width: 70%;
  height: 100%;
  border-radius: 3px;
  text-align: center;
}
.analytics-guide h2 {
  color: lightgray;
  font-family: 'Montserrat';
}
.analytics-guide p {
  color: lightgray;
  font-size: 16px;
}
.analytics-guide img {
  opacity: 0.4;
}
.analytics-guide svg {
  font-size: 35px;
  opacity: 0.4;
}

/* omnichannel */
.omnichannel-section {
}
.omni-sidebar-menu-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 30px;
  flex-direction: column;
  background: white;
  padding: 10px;
  border-radius: 5px;
}

.omni-sidebar-menu-wrapper ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 15px;
  flex-direction: column;
}
.omni-sidebar-menu-wrapper li {
  background: white;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.omni-sidebar-menu-wrapper li:hover {
  background: #f1f1f1;
  border-radius: 8px;
}
.omni-sidebar-menu-wrapper li a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-weight: 600;
}
.omni-sidebar-menu-wrapper li svg {
  font-size: 22px;
}
.omni-tabs {
  border: 1px solid #d3d3d352;
  padding: 8px 10px;
  border-radius: 10px;
  margin: 10px 0px;
}
.omni-tabs ul {
  display: flex;
  justify-content: flex-start;
  grid-gap: 6px;
  align-items: center;
}
.omni-tabs li {
  border-radius: 50%;
  background: #d3d3d352;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
}
.omni-tabs li img {
  width: 100%;
}

/* chatlogs chatbot */

/* admin dashboard */
.chatbox-header-right-options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 20px;
}
.chatbox-header-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.chatbox-header-icon img {
  width: 14px;
  filter: brightness(0.5);
  cursor: pointer;
  transition: var(--transition);
}
.chatbox-header-icon svg {
  /* fill: var(--gray-color); */
  color: var(--gray-color);
  font-size: 20px;
  cursor: pointer;
  transition: var(--transition);
}

/* site cradit */
.chatbot-creator {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 5px;
}
.chatbot-creator p {
  font-size: 11px;
  color: white;
}
.chatbot-creator img {
  width: 18px;
  border-radius: 3px;
  object-fit: contain;
}
.chatbot-widget-options,
.chatbot-widget-minimize {
  fill: white;
  border-radius: 50%;
  font-size: 25px;
  position: relative;
  cursor: pointer;
}
.chatbot-widget-options svg,
.chatbot-widget-minimize svg {
  fill: white;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  padding: 3px;
  transition: var(--transition);
}

.chatbot-widget-minimize svg {
}

.chatbot-widget-options svg:hover,
.chatbot-widget-minimize svg:hover {
  background: rgba(0, 36, 92, 0.16);
  border-radius: 50%;
}

/* chatbot body */
.chat-conversation-wrapper {
  padding: 0px 15px;
  width: 100%;
  overflow: hidden auto;
  background: var(--bg-light);
  transition: all 0.3s ease 0s;
  flex: 0 1 auto;
  scrollbar-width: thin;
  position: relative;
  /* max-height: 390px;
  min-height: 160px;
   */
  height: 72vh;
}
.chatbox-conversation {
  /* max-height: 75vh;
  min-height: 420px;
  height: 100%; */
  border-left: 2px solid var(--bg-light);
}
.chatbox-conversation-form {
  /* position: fixed;
  bottom: 0px;
  width: 618px; */
  z-index: 999;
  background: var(--bg-light);
}
/* width */
.chat-conversation-wrapper::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.chat-conversation-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.chat-conversation-wrapper::-webkit-scrollbar-thumb {
  background: rgb(204, 204, 204);
  border-radius: 10px;
}

/* Handle on hover */
.chat-conversation-wrapper::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}
.message-body {
  position: relative;
  margin-top: 10px;
  width: 100%;
  /* padding-bottom: 30px; */
  float: left;
}
.message-body li {
  margin: 0 0px 5px 0px;
  overflow-wrap: break-word;
  display: inline-block;
  clear: both;
  position: relative;
  transition: margin 0.28s ease-in-out 0s;
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  align-items: flex-start;
  /* margin-bottom: 10px; */
  min-width: 70px;
  max-width: 90%;
}
.chat-bubble-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: -webkit-fill-available;
}
.chat-bubble-wrap div {
  font-size: 13px;
  line-height: 20px;
  color: #000;
}
/* message agent */
/* message visitor */

.agent-logo {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: contain;
  padding: 2px;
  box-shadow: 0px 0px 4px 0px lightgray;
}
.message-operator .messageTimestamp {
  height: 23px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  top: calc(100% + 4px);
  left: 12px;
}
.messageTimestamp,
.bot-date-response-badge {
  bottom: -20px;
  font-size: 9px;
  color: rgb(136, 148, 171);
  position: absolute;
  transition: all 0.2s ease 0s;
  white-space: nowrap;
  right: 0px;
}
.bot-date-response-badge {
  left: 0px;
}
/* message babble */
.animate-typing .dot {
  -webkit-animation: wave 1.3s linear infinite;
  animation: wave 1.3s linear infinite;
  background-color: #7269ef;
  border-radius: 50%;
  display: inline-block;
  height: 4px;
  margin-right: -1px;
  opacity: 0.6;
  width: 4px;
  margin-right: 5px;
}
.animate-typing .dot:nth-child(2) {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.animate-typing .dot:nth-child(3) {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
  margin-right: 0px;
}

@-webkit-keyframes wave {
  0%,
  60%,
  to {
    -webkit-transform: none;
    -webkit-transform: initial;
    transform: none;
  }
  30% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
@keyframes wave {
  0%,
  60%,
  to {
    -webkit-transform: none;
    -webkit-transform: initial;
    transform: none;
  }
  30% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

/* chatbot footer */
.chatbot-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  height: 70px;
  grid-gap: 10px;
  margin-top: 2px;
  position: relative;
  border-top: 2px solid #f7f7f7;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: var(--white-color);
  z-index: 99;
}
.chatbot-footer form {
  width: 100%;
}
.relative {
  position: relative;
}
/* for admin dashboard */
.chatbot-footer-input-box {
  width: 100%;
  height: 40px;
  position: relative;
}
.chatbot-footer-input-box input {
  padding: 5px;
  border: 1px solid #80808033;
  border-radius: 10px;
  height: 45px;
  width: 100%;
  padding-left: 15px;
  font-family: var(--font-flexo);
  background: var(--bg-light);
  font-weight: 400;
  font-size: 14px;
  color: #000;
}
.demo-message-agent {
  text-align: left;
}
.chatbot-footer-input-box input:focus {
  outline: none;
}
.chatbot-send-message-button {
  /* border: unset; */
  background: var(--brand-color);
  color: white;
  border-radius: 30px;
  font-size: 17px;
  /* position: absolute; */
  /* right: 11px; */
  /* top: 3px; */
  /* bottom: 5px; */
  padding: 5px;
  width: 50px;
  /* height: 35px; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 30px;
  grid-gap: 7px;
  text-align: center;
  overflow: hidden;
}
.send-msg-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  grid-gap: 6px;
}
.chatbot-send-message-button svg {
  color: white;
  font-size: 16px;
}

/* chatlogs new */
.omni-profile-overview {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
}
.omni-profile-card {
  background: white;
  border-radius: 10px;
  padding: 10px;
}
.omni-profile-card-static {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 10px;
}
.profile-card-toggle-content {
  width: 100%;
}
.omni-profile-card h2 {
  font-size: 20px;
  margin-bottom: unset;
}
.omni-profile-card p {
  font-size: 15px;
}
.omni-profile-card img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
}
.omni-ticket-history {
  background: white;
  border-radius: 10px;
  padding: 20px;
  height: auto;
}
.omni-ticket-history.model {
  padding: unset;
  margin: 10px 0px;
}
.create-member-modal {
  padding: unset;
  margin: 10px 0px;
}
.create-member-modal .group-member-wrapper {
  max-height: 450px !important;
}
.omni-ticket-history h2 {
  font-size: var(--title-size);
}
.all-ticket-list-screen {
  background: var(--white-color);
  padding: 20px;
  border-radius: 10px;
}
.ticket-history-filter {
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 8px;
}
.ticket-history-filter input,
.ticket-history-filter select {
  height: 35px;
  padding: 5px 10px;
  border-radius: 8px;
  font-family: var(--font-flexo);
}
.ticket-history-filter select {
  font-size: 15px;
}
.ticket-history-filter .form-group {
  margin-bottom: unset;
}
.full-width {
  width: 100%;
  max-width: 100%;
}
.ticket-history-list {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  justify-content: flex-start;
  overflow-y: scroll;
  max-height: 230px;
  border: 1px solid var(--border-color);
  padding: 15px;
  border-radius: 5px;
}
.ticket-history-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  padding-right: 5px;
}
.ticket-history-item {
}
.ticket-history-status {
  border-radius: 10px;
  min-width: 65px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.ticket-history-status.pending {
  background: #ffd60061;
  border: 1px solid #ff9a02;
  color: #ff9a02;
}
.ticket-history-status.opened {
  background: #06bf206b;
  border: 1px solid #008000;
  color: green;
}
.ticket-history-status.closed {
  color: #f60808;
  border: 1px solid #f60808;
  background: #f6080829;
}
.omni-footer-button-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  font-size: 17px;
  position: absolute;
  right: 20px;
  top: 8px;
  bottom: 5px;
}
.omni-footer-button-wrap svg {
  position: unset;
  width: 30px;
  height: 25px;
  border-radius: 5px;
}
.chatlogs-send-btn {
  background: unset !important;
  padding: unset !important;
  color: var(--brand-color) !important;
}

.chat-send-time {
  font-size: 9px;
  margin-top: 16px;
  position: absolute;
  bottom: -4px;
  right: 17px;
  width: max-content;
}
.chat-message-wrapper {
  position: relative;
  width: 100%;
}
.channel-right {
  position: relative;
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  grid-gap: 10px;
  transition: all 0.3s ease-in-out;
}
.channel-right svg {
  font-size: 16px !important;
}
.create-group-logo {
  width: 80px;
  height: 80px;
}
.omni-chat-header {
  box-shadow: unset;
  padding: 10px;
  border-bottom: 1px solid var(--border-color);
  border-left: unset;
  border-right: unset;
}
.omni-chat-header:hover {
  background: unset;
}
.omni-chat-header:hover .omni-chat-header svg {
  background: unset;
}
.agent-bg {
  background: #06bf20;
}

.omni-chat-header .channel-right {
  padding-right: 15px;
}
.omni-source-icon {
  position: absolute;
  right: -2px;
  bottom: -4px;
  width: 20px !important;
  border-radius: 50%;
  height: 20px !important;
  box-shadow: var(--shadow);
}

/***chatbot***
***chatbot***/
.bot-demo-wrapper {
  position: absolute;
  background: white;
  width: 100%;
  inset: 0;
  height: 100%;
  background-image: url('https://images.unsplash.com/photo-1553095066-5014bc7b7f2d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bGlnaHRpbmd8ZW58MHx8MHx8&w=1000&q=80');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.chatbot-section {
  position: relative;
}
.chatbot-widget-wrapper {
  position: fixed;
  right: 30px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  grid-gap: 15px;
  z-index: 99999;
}
.chatbot-widget {
  background: var(--bg-light);
  border-radius: 8px;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  box-shadow: rgb(0 18 46 / 16%) 0px 8px 36px 0px;
  height: 0px;
  width: 372px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  opacity: 0;
  position: relative;
  z-index: -1;
  /* display: none; */
}
.chatbot-widget.active {
  visibility: visible;
  opacity: 1;
  height: 530px;
  z-index: 999;
  display: block;
  overflow: unset;
}
.chatbot-widget-agent-precence {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.chatbot-widget-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: var(--secondary-bg);
  padding: 5px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.chatbot-times-icon {
  box-shadow: var(--brand-shadow);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in;
  overflow: hidden;
  z-index: 9999;
  position: absolute;
  left: -35px;
  top: 0px;
}
.chatbot-times-icon:hover .chatbot-times-icon svg {
  color: green;
}
.chatbot-times-icon svg {
}
.chatbot-times-icon img {
}

.chatbot-widget-header-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 8px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.chatbot-widget-recipient-details {
  color: white;
}
.chatbot-widget-recipient-details p {
  font-weight: 300;
  font-size: 13px;
  color: white;
  margin: unset;
  margin-bottom: 2px;
}
.chatbot-widget-recipient-details h3 {
  font-weight: 600;
  line-height: 10px;
  font-size: 13px;
  color: white;
  padding: 0;
}
.chatbot-widget-header-right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: white;
  flex-direction: column;
  position: relative;
}
.chatbot-widget-header-right-options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 3px;
}
.chatbot-recipient-avatar {
  position: relative;
}
.online-status {
  position: absolute;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background: var(--blue-color);
  border: 2px solid white;
  top: 0px;
  right: 0px;
}
.delete-chat-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 5px;
}
.chatbot-recipient-avatar img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: contain;
  background: var(--bg-light);
  box-shadow: var(--brand-shadow);
  border: 2px solid white;
}
.chatbot-widget-options,
.chatbot-widget-minimize {
  fill: white;
  border-radius: 50%;
  font-size: 25px;
  position: relative;
  cursor: pointer;
}
.chatbot-widget-options svg,
.chatbot-widget-minimize svg {
  fill: white;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  padding: 3px;
  transition: var(--transition);
}

.chatbot-widget-minimize svg {
}

.chatbot-widget-options svg:hover,
.chatbot-widget-minimize svg:hover {
  background: rgba(0, 36, 92, 0.16);
  border-radius: 50%;
}
.bot-message-wrapper {
  height: 400px !important;
}
.chatbot-message-body {
  padding: 5px 0px !important;
}
.message-body {
  position: relative;
  margin-top: 10px;
  width: 100%;
  /* padding-bottom: 30px; */
  float: left;
}
.message-body li {
  margin: 0 0px 25px 0px;
  overflow-wrap: break-word;
  display: inline-block;
  clear: both;
  position: relative;
  transition: margin 0.28s ease-in-out 0s;
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  align-items: flex-start;
  /* margin-bottom: 10px; */
  min-width: 70px;
}
.agent-logo {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
  padding: 2px;
  box-shadow: 0px 0px 4px 0px lightgray;
}
.chat-bubble-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: -webkit-fill-available;
}
.chat-bubble-wrap div {
  font-size: 13px;
  line-height: 20px;
  color: #000;
}
button.suggest-btn {
  border-radius: 4px;
  padding: 9px 13px;
  display: flex;
  justify-content: flex-start;
  grid-gap: 5px;
  border: 1px solid var(--green-color);
  align-items: center;
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-size: 13px;
  background: var(--bg-light);
  color: var(--green-color);
  transition: all 0.3s ease-in-out;
  text-align: left;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.suggest-btn:disabled {
  background: #80808061;
  opacity: 0.5;
  cursor: not-allowed;
  color: black;
  border: 1px solid gray;
}

button.suggest-btn img {
}
button.suggest-btn span {
}
.clear-style-message {
  padding: unset !important;
  background: unset !important;
  box-shadow: unset;
}
button.suggest-btn:hover {
  background: var(--green-bg);
  color: white;
}

/* chatbot footer */
.chatbot-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  height: 70px;
  grid-gap: 10px;
  margin-top: 2px;
  position: relative;
  border-top: 1px solid var(--border-color);
}
.chatbot-footer form {
  width: 100%;
}
.relative {
  position: relative;
}
/* for admin dashboard */
.chatbot-footer-input-box {
  width: 100%;
  height: 40px;
  position: relative;
}
.chatbot-footer-input-box input {
  padding: 5px;
  border: 1px solid var(--bg-light);
  border-radius: 7px;
  height: 40px;
  width: 100%;
  padding-left: 20px;
  font-family: var(--font-flexo);
  background: var(--bg-light);
  font-weight: 400;
  font-size: 14px;
  color: #000;
}
.mini-bot-footer {
}
.mini-bot-footer input {
  border-radius: 30px;
}
.mini-bot-footer button {
  border-radius: 30px;
  width: 62px;
  height: 33px;
}
.mini-bot-footer svg {
}
.chatbot-send-message-button {
  border: unset;
  background: var(--secondary-bg);
  color: white;
  border-radius: 7px;
  font-size: 17px;
  position: absolute;
  right: 5px;
  top: 3px;
  bottom: 5px;
  padding: 0px;
  width: 70px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  grid-gap: 7px;
  text-align: center;
  overflow: hidden;
}
.chatbot-mini-preview-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--secondary-bg);
  border-top-left-radius: 5px;
  border-top-right-radius: 20px;
  padding: 6px 20px;
  grid-gap: 35px;
  cursor: pointer;
}
.chatbot-mini-preview-wrap.active {
  display: none;
}
.chatbot-mini-preview-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}
.bot-preview-img {
  width: 19px;
}
.send-us-message {
  font-weight: 400;
  color: white;
}
.chatbot-mini-preview-right {
}
.chatbot-mini-preview-right h5 {
  font-size: 12px;
  color: white;
  font-weight: 500;
}
.active-menu {
  background: var(--brand-color);
  color: white;
  border-radius: 8px;
  box-shadow: var(--shadow);
}
.active-menu svg {
  color: var(--white-color);
}

/* layout */
.layout-section {
  background: #dcdcdc;
}
.layout-container {
}
.layout-wrapper {
  display: grid;
  grid-template-columns: 0.5fr 12fr;
  grid-gap: 20px;
  padding: 20px;
}
.layout-body {
}
.layout-menus {
}

.ant-popover .ant-popover-title {
  margin: unset !important;
  min-width: max-content !important;
}
.ant-popover .ant-popover-inner {
  padding: 10px 15px !important;
}
.calendar-container {
}
.calendar-left-sidebar,
.calendar-right-body {
  background: var(--white-color);
  padding: 20px;
  border-radius: 5px;
}
.group-member-item {
  border-left: 1px solid var(--border-color) !important;
}
.group-member-wrapper {
  margin-top: 15px;
}
.group-member-wrapper.modal {
  padding: unset;
}
.add-new-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  justify-content: center;
  grid-gap: 5px;
  cursor: pointer;
  font-weight: 600;
}
.react-body-big-calendar {
  height: 70vh;
}
.react-mini-calendar {
  height: 300px;
  margin-top: 15px;
}
.react-mini-calendar .rbc-toolbar {
  display: none;
}
.profile-details {
}
.profile-details strong {
}
.profile-details li {
}

/* Style the profile details */
.profile-details {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.profile-details li {
  margin-bottom: 10px;
}

.profile-card-toggle-accordion {
  background: unset;
  border: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: var(--font-flexo);
}

.profile-card-toggle-accordion .ant-collapse-header-text {
  font-weight: 500;
  border: 1px solid var(--border-color);
  padding: 6px 20px;
  border-radius: 5px;
}
.chat-emoji-box {
  position: absolute !important;
  bottom: 49px;
  right: -4px;
  border-bottom-right-radius: unset !important;
  border-bottom-left-radius: unset !important;
}
.chat-emoji-box input {
  padding-left: 35px;
}
.meeting-active-block {
}
.meeting-active-title {
  color: white;
  font-size: 13px;
  font-weight: 500;
}
.meeting-time-wrap {
  display: flex;
  grid-gap: 3px;
}
.meeting-time-wrap p {
  font-size: 10px;
  color: white;
  line-height: 14px;
}

.group-react-chat {
  max-height: unset !important;
}
.chat-search-input {
  font-family: var(--font-flexo);
  padding: 5px 10px;
  border-radius: 8px;
}
.meeting-location {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

/* antd */
.ant-list-item-meta-content {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.ant-list-item-meta-title {
  margin: unset;
}
.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: var(--brand-color) !important;
}

.ant-pagination-prev,
.ant-pagination-next {
  min-width: unset !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* Antd */
.antd,
.ant-select-item,
.ant-dropdown-menu,
.ant-pagination,
.ant-pagination-item {
  font-family: var(--font-flexo) !important;
}
.ant-pagination-item-active {
  border-color: #87653f !important;
  color: #87653f !important;
}
.ant-pagination-item-active a{
  color: #87653f !important;
}
