/* User Section */

/* .user-container {
  background: white;
  margin: 2em;
  height: 100vh;
  border-radius: 5px;
  padding: 1.5em;
} */

/* .container {
  max-width: 1250px;
  margin: auto;
  padding: 0px 20px 0px 20px;
} */
.user-section {
}
.user-container {
  background: var(--white-color);
  padding: 30px;
  border-radius: 3px;
}
h3 {
  text-align: center;
  padding: 30px 0px;
}

.user-create-button {
  color: white;
  background: var(--brand-color);
  height: 30px;
  width: 120px;
  border-radius: 3px;
}

.user-view-button {
  color: white;
  background: var(--brand-color);
  height: 30px;
  width: 90px;
  border-radius: 3px;
  font-size: 12px !important;
}

.search-user {
  display: flex;
  align-items: center;
  gap: 1em;
}

.search-user-box {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px;
  border: 1px solid #2f4f4fc9;
  border-radius: 6px;
}

.search-user-box > input {
  border: none;
  height: 5px;
}
.search-user-box > input:focus {
  border: none;
}
.plan-package {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-right: auto;
  text-wrap: nowrap;
}
.plan-package-box {
  border: 1px solid #2f4f4fc9;
  border-radius: 6px;
  color: #2f4f4fc9;
  padding: 1px 6px;
}

span {
  /* color: black; */
}
.pagination-status {
  display: flex;
  align-items: center;
  justify-content: first baseline;
  gap: 1.7rem;
  margin: 20px 0px;
}
.pagination-status > p > span {
  color: black;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
  cursor: pointer;
  display: inline-block;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #eee;
}

.pagination .active {
  /* background-color: #3498db; */
  background-color: var(--brand-color);
  color: white;
}

.table-container {
  overflow-x: auto;
}

table {
  font-family: var(--font-flexo);
  border-collapse: collapse;
  width: 100%;
}
td,
th {
  color: #2f4f4fc9;
  border-bottom: 1px solid #6d6d6d4f;
  text-align: left;
  padding: 6px;
  /* padding-left: 8rem; */
  /* min-width: 350px; */
}

.table-button {
  display: flex;
  align-items: center;
  gap: 10px;
}
