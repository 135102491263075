@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  font-family: var(--font-flexo);
}

/* Apply to Ant Design components */
.ant-table,
.ant-input,
.ant-select,
.ant-button,
.ant-picker,
.ant-drawer,
.ant-tag,
.ant-btn,
.ant-btn-icon,
.ant-select-item-option-content,
.ant-tabs-tab-btn,
.ant-collapse-header-text,
.ant-collapse-header-text span,
.ant-collapse-content-box,
.ant-btn-primary {
  font-family: var(--font-flexo) !important;
}

.all-ticket-list-screen .container {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.062);
}

.all-ticket-list-screen h3 {
  font-size: 1.5em;
  color: #333;
  text-align: left;
  margin-bottom: unset;
  padding: 20px 0px;
}

.ant-table {
  background-color: white;
  border-radius: 8px;
}

.ant-table-thead > tr > th {
  background-color: #f0f0f0;
  font-weight: bold;
  border-bottom: 2px solid #ddd;
}

.ant-table-tbody > tr > td {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.ant-tag-green {
  background-color: var(--brand-color);
  color: white;
}

.ant-tag-gold {
  background-color: #faad14;
  color: white;
}

.ant-tag-red {
  background-color: #f5222d;
  color: white;
}
.ticket-details p {
  font-size: 14px;
  margin-bottom: unset;
  line-height: 23px;
  font-family: var(--font-flexo);
  color: #000000;
}
.ticket-details p strong {
  font-weight: 600;
}
.ticket-title {
  padding: unset !important;
  box-shadow: unset !important;
  border-radius: unset !important;
}
.ticket-kpi-wrapper {
  margin-bottom: 20px;
}
.ticket-kpi-wrapper .dashboard-kpi-item {
}
.ticket-kpi-wrapper .dashboard-kpi-header {
  display: flex;
  margin-bottom: unset;
  justify-content: center;
  align-items: center;
}
.ticket-kpi-wrapper .dashboard-kpi-header p {
  margin-bottom: 0px;
}
.open-tickets {
  background-color: #e6f7ff; /* Example: Light blue background */
  /* Add any other styles you need for open tickets */
}
.ant-table-cell b {
  font-weight: 600;
}
.open-tickets .ant-table-cell {
  font-weight: 700;
}
.open-tickets .ant-table-cell b {
  font-weight: 700;
}
.right-sidebar-drawar {
  background: #dcdcdc !important;
}
.right-sidebar-drawar .ant-drawer-body {
  padding: 15px;
  border-radius: 7px;
  box-shadow: var(--shadow);
}
.ticket-drawar-title {
  margin-bottom: 20px;
}
.ticket-drawar-title h2 {
  font-size: 22px;
}

.note-container {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.note-header {
  font-weight: 600;
  background: #80808057;
  padding: 8px 10px;
  font-size: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.note-textarea {
}

.note-footer {
  text-align: right;
}
.attach-button {
  background-color: white;
  border-color: var(--brand-color);
  color: var(--brand-color);
  font-size: 14px;
  font-weight: 500;
  padding: 5px 12px;
  height: unset;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--brand-color) !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--brand-color) !important;
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: var(--brand-color) !important;
}
.ant-btn-primary {
  background: var(--brand-color) !important;
  color: white !important;
}
.ant-btn-primary.ant-btn-dangerous {
  background: #f44336 !important;
}
.right-sidebar-drawar .ant-tabs-tab {
  min-width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgray;
  margin: unset !important;
  padding: 8px 0px;
}
.right-sidebar-drawar .ant-tabs-tab.ant-tabs-tab-active {
}
:where(.css-dev-only-do-not-override-1qhpsh8).ant-tabs-top
  > .ant-tabs-nav::before {
  border-bottom: unset !important;
}

.ticket-sidebar-body-wrap {
}
.ticket-note-box {
  background-color: #f0f0f0;
  height: fit-content;
  box-shadow: var(--shadow);
  border-radius: 10px !important;
  overflow: hidden;
}
.ticket-sidebar-box {
  background: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: var(--shadow);
}
.note-text-editor-wrap {
}
.ql-snow * {
  font-family: var(--font-flexo) !important;
}
.ql-editor {
  border-radius: 3px !important;
  height: 100px !important;
}

.ant-tabs-tab-btn {
  font-weight: 500;
}
.ticket-sidebar-box h3 {
  text-align: left;
  font-size: 17px;
  margin: unset;
  padding: 8px 0px;
  padding-top: unset;
}
.ticket-details h2 {
  font-size: 17px;
  text-decoration: underline;
}
.ticket-details-group {
  position: relative;
  margin-bottom: 15px;
}
.select-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  margin-bottom: 10px;
}
.appointment-log {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 7px 0px;
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.appointment-log-btn {
  background: #d0d0d0;
  padding: 3px 10px;
  border-radius: 10px;
  font-weight: 600;
}
.ticket-details .drawer-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ant-tabs-nav-wrap {
  justify-content: center;
  align-items: center;
}

.ant-tabs-tab:hover {
  color: var(--brand-color) !important;
}